import React from 'react';
import Layout from '../components/layout';
import ThankYou from '../components/thank-you/ThankYou';

export default function thankYou() {
  return (
    <Layout>
      <ThankYou />
    </Layout>
  );
}
