import React from 'react';
import globalStyles from '../styles/Main.module.css';
import styles from '../styles/ThankYou.module.css';

const ThankYou = () => {
  return (
    <>
      <div className={globalStyles.section}>
        <h1 className={styles.sectionTitle}>Thank You!</h1>
      </div>
    </>
  );
};

export default ThankYou;
